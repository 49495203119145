import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import BlogCard from "./BlogCard";
import BlogCardMicro from "./BlogCardMicro";
// import Pagination from "../Blog/Pagination";
// import useSiteMetadata from "../SiteMetadata";

const BlogLatestQuery = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: {
          fields: { slug: { regex: "//blog//" } }
        }

        sort: { order: DESC, fields: frontmatter___date }
        limit: 9
      ) {
        edges {
          node {
            timeToRead
            frontmatter {
              title
              path
              tags
              date(formatString: "MMMM DD, YYYY")
              excerpt
              image {
                publicURL
                childImageSharp {
                  gatsbyImageData(placeholder: NONE)
                }
              }
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `);
  return data.allMarkdownRemark.edges;
};
const BlogLatest = ({ mini, title, preview }) => {
  // const { meta, social } = useSiteMetadata();
  var posts = [];
  if (!preview) {
    posts = BlogLatestQuery();
  } else {
    posts = [
      {
        node: {
          timeToRead: 2,
          frontmatter: {
            title: "Blog title",
            path: null,
            tags: "Blog Tag",
            date: "December 15, 2022",
            excerpt: "Blog content excerpt here",
            image: "",
          },
          fields: {
            slug: "/blog/",
          },
        },
      },
    ];
    posts[1] = posts[2] = posts[3] = posts[4] = posts[5] = posts[0];
  }

  return (
    <section className="bg-light">
      <header className="container-fluid">
        <div className="row contained-xl justify-content-center">
          <div className="col-lg-10 mt-5 mb-4">
            <h2 className="font-family-display">{title}</h2>
          </div>
        </div>
      </header>
      <section className="postlists container-fluid">
        <div className="row contained-xl justify-content-center">
          <div className="col-md-12 col-lg-10">
            <div className="row pb-5 contained-xl">
              {posts &&
                posts.map(function ({ node: post }, index) {
                  return (
                    <div
                      key={"block_1_" + index}
                      className="col-md-6 col-lg-4 mb-4"
                    >
                      {index < 3 ? (
                        <BlogCard
                          featured_media={post.frontmatter.image}
                          title={post.frontmatter.title}
                          date={post.frontmatter.date}
                          excerpt={post.frontmatter.excerpt}
                          tag={post.frontmatter.tags}
                          slug={post.fields.slug}
                          readtime={post.timeToRead}
                        />
                      ) : (
                        <BlogCardMicro
                          featured_media={post.frontmatter.image}
                          title={post.frontmatter.title}
                          date={post.frontmatter.date}
                          excerpt={post.frontmatter.excerpt}
                          tag={post.frontmatter.tags}
                          slug={post.fields.slug}
                          readtime={post.timeToRead}
                        />
                      )}
                    </div>
                  );
                })}
              <div className="col-md-12 text-center mt-4">
                <Link to="/blog/" className="btn btn-outline-primary">
                  View more in the blog
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default BlogLatest;
